.taskbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #191919c6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 10px; */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
    z-index: 100;
  }

  .taskbar-items {
    flex-grow: 1;
    display: flex;
    justify-content: left;
    overflow:auto;
  }
  
  .taskbar-item {
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #5555558a;
    height: 40px;
    color: white;
    border: none;
    margin-right: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
    flex-shrink: 0;
    width: 180px;
    font-size: 14px;
  }

  .taskbar-item .taskbar-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }


  
  .taskbar-item:hover {
    background-color: #777;
  }
  
  .taskbar-item.minimized {
    opacity: 0.4;
  }
  
  .taskbar-item.inactive {
    opacity: 0.8;
  }