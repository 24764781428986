.app-center {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;

  }

  .main-page {
    margin-left: 20px;
    margin-right: 20px;
  }
  
  /* Carousel Styles */
  .carousel {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  .carousel-item {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
    padding: 20px;
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
    transition: opacity 0.5s ease-in-out;
    white-space: normal;
    word-wrap: break-word;
  }
  
  .carousel-content {
    background: rgba(0, 0, 0, 0.5);
    padding: 15px 20px;
    border-radius: 8px;
  }
  
  .carousel-content h2 {
    margin: 0 0 10px;
    font-size: 24px;
    color: #fff;
  }
  
  .carousel-content p {
    margin: 0 0 15px;
    font-size: 16px;
  }
  
  .details-button {
    padding: 10px 15px;
    background-color: #0078d7;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .details-button:hover {
    background-color: #005bb5;
  }
  
  .app-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    margin-bottom: 15px;
  }

  .app-item {
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    transition: transform 0.2s;
    /* margin-bottom: 10px; */
    background-color: #F5F8FA;
    border-radius: 12px;
    transition: all 0.3s ease;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #E0E6ED;
}

  .app-item:hover {
      transform: scale(1.02);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      background-color: #E8F0FE;
  }

  .error-message h3 {
    margin: 0;
    margin-top: 20px;
    font-size: 30px;
  }

  .error-message p {
    margin: 0;
    font-size: 20px;
  }
  
  
  .app-icon {
    font-size: 36px;
    margin-right: 15px;
  }
  
  .app-info h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  
  .app-info p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #666;
  }
  
  .app-page {
    margin-top: 20px;
    animation: slideIn 0.3s forwards;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .app-center-back-button {
    display: inline-block;
    margin-bottom: 20px;
    padding: 10px 15px;
    background-color: #0078d7;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .app-center-back-button:hover {
    background-color: #005bb5;
  }

  .screenshots {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    overflow: scroll;
  }

  .screenshots-container {
    max-width: 400px;
    margin-right: 15px;
    display: block;
  }
  
  .screenshots img {
    width: 100%;
    width: 400px;
    border-radius: 8px;
  }

  .screenshots-desc {
    word-break: break-all;
    word-wrap: break-word;
  }