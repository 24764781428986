.notepad {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.text-input {
    width: 100%;
    height: 100%;
}
