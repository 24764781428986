.launch-button {
    width: 40px;
    height: 40px;
    font-size: large;
    align-items: center;
    background-color: transparent;
    color: white;
    border: none;
    margin-right: 10px;
    margin-left: 10px;
    padding: 4px;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
    /* flex-shrink: 0; */
  }

.launch-button > * {
    height: 75%;
  }

.launch-button:hover {
    background-color: rgba(173, 216, 230, 0.5);
}

.launch-button.clicked {
    background-color: rgba(0, 191, 255, 0.501);
}