.terminal {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: none;
}

.terminal-input {
    background-color: black;
    color: white;
    outline: none;
    width: 100%;
    height: 100%;
}
