@keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  

.launcher {
  position: fixed;
  bottom: 50px;
  left: 0;
  background-color: #191919c6;
  border-radius: 8px;
  padding: 16px;
  margin: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 999;
  width: 542px;
  height: 500px;
  will-change: transform;
}

.launcher-mobile {
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  background-color: #191919f2;
  border-radius: 0px;
  padding: 0px;
  margin: 00px;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.3);
  z-index: 999;
  width: 100%;
  height: 100%;
  will-change: transform;
  overflow: hidden;
}

.launcher-header-mobile {
  padding: 16px;
  overflow: hidden;
}

.launcher.visible {
    animation: slideUp 0.2s ease-in-out forwards;
  }
  
.launcher.hidden {
    animation: slideDown 0.2s ease-in-out forwards;
  }

.launcher-content {
    min-width: 600px;
    min-height: 500px;
    color: white;
}

.launcher-content-mobile {
  min-width: unset;
  min-height: unset;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: white;
  overflow: hidden;
}

.launcher-body {
    display: flex; /* Enable flexbox */
    flex-wrap: wrap; /* Allow wrapping to next line */
    flex-direction: row; /* Items flow in a row */
    align-content: flex-start; /* Align rows within the container */
    justify-content: flex-start; /* Align items within each row */
    height: 100%;
    width: 100%;
}

.launcher-body-mobile {
  justify-content: center; /* Align items within each row */
  overflow: hidden;
}

.launcher-body > * {
    margin-right: 10px; 
    margin-bottom: 10px;
}

.launcher-body-mobile > * {
  margin-right: unset; 
  margin-bottom: unset;
  overflow: hidden;
}

.launcher button {
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.launcher button:hover {
    background-color: #666;
}
